import { createSlice } from '@reduxjs/toolkit';
import getListJobsAction from './getListJobsAction';

const initialState = {
  listJobs: null,
  isLoading: false,
};

export const jobAdSlice = createSlice({
  name: 'jobAd',
  initialState,
  reducers: {
    setListJobs: (state, { payload }) => ({
      ...state,
      listJobs: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListJobsAction.pending, (state) => {
      if (!state.listJobs || state.listJobs?.length === 0)
        return { ...state, isLoading: true };

      return state;
    });
    builder.addCase(getListJobsAction.fulfilled, (state, { payload }) => ({
      ...state,
      listJobs: payload,
      isLoading: false,
    }));
  },
});

// Action creators are generated for each case reducer function
export const { setListJobs } = jobAdSlice.actions;

export default jobAdSlice.reducer;
