import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col } from 'reactstrap';
import filter from 'lodash/filter';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie-files/not-found.json';
// import APIs
import { getListRatingsAPI } from 'api/rating';
// import redux actions
import getListRatingAction from 'redux/rating/getListRatingAction';
import Job from 'search/Job';
import Loading from './Loading';
import './index.css';

const Ratings = () => {
  const [listRatings, setListRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth0();

  useEffect(() => {
    const getListRatings = async () => {
      try {
        const ratings = await getListRatingsAPI(user.email);
        setListRatings(filter(ratings, (rating) => rating.title));
        setIsLoading(false);
      } catch (ex) {
        console.error(ex);
        setIsLoading(false);
      }
    };

    if (user?.email) {
      setIsLoading(true);
      dispatch(getListRatingAction(user?.email));
      getListRatings();
    }
  }, [user?.email]);

  return (
    <div className="container rating-page">
      <p className="title">Your ratings</p>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {listRatings?.length === 0 && (
            <div className="no-rating">
              <Player
                className="lottie-animation"
                autoplay
                loop
                src={animationData}
              />
              <div className="no-rating-title">No ratings found</div>
              <br />
              <p>Please try rating some jobs.</p>
            </div>
          )}
          <Row>
            {listRatings.map((job, index) => (
              <Col xs="12" sm="6" key={job.key}>
                <Job
                  key={job?.id}
                  title={job?.title}
                  desc={job?.desc}
                  dest_url={job?.dest_url}
                  jobInfo={job}
                  index={index}
                  {...job}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default Ratings;
