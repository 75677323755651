import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import axios from './index';

export const createSubscriptionAPI = async (data) => {
  try {
    const result = await axios({
      method: 'post',
      url: '/store/any/subscriptions',
      data,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    throw ex;
  }
};

export const getListSubscriptionsAPI = async (email) => {
  try {
    const result = await axios({
      method: 'get',
      url: `store/any/subscriptions/${email}`,
    });
    return result?.data?.subscriptions;
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

export const cancelSubscriptionAPI = async (data) => {
  try {
    const result = await axios({
      method: 'post',
      url: '/store/any/subscriptions/cancel',
      data,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    throw ex;
  }
};

export const getListPricingAPI = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: `store/any/subscriptions`,
    });
    return result?.data?.products;
  } catch (ex) {
    console.error(ex);
    return [];
  }
};

export const getSubscriptionsAPI = createApi({
  reducerPath: 'subscriptions',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    getSubscriptionData: builder.query({
      query: () => `/store/any/subscriptions`,
      transformResponse: (response) => response.products,
    }),
    initializeCheckout: builder.mutation({
      query: (amount) => ({
        url: `/store/any/checkout`,
        method: 'POST',
        body: { amount },
      }),
    }),
    finalizeCheckout: builder.mutation({
      query: (stripe_id) => ({
        url: `/store/any/checkout2`,
        method: 'POST',
        body: { stripe_id },
      }),
      transformResponse: (response) => {
        console.log(response);
        return response;
      },
    }),
  }),
});

export const {
  useGetSubscriptionDataQuery,
  useInitializeCheckoutMutation,
  useFinalizeCheckoutMutation,
} = getSubscriptionsAPI;
