import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import Byline from './Byline';
import './MainBlog.css';

function MainBlog(props) {
  const { ptitle } = props;
  const { main_img_src } = props;
  const { author_img_src } = props;
  const { title } = props;
  const { desc } = props;
  const { author_name } = props;
  const { date } = props;
  const href = '/blog/cat/slug';
  return (
    <div className="MainBlog">
      <Helmet>
        <title>{ptitle}</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="6">
            <img className="MainBlogImage" src={main_img_src} alt="" />
          </Col>
          <Col xs="6">
            <div className="MainBlogTitle">
              <a href={href}>{title}</a>
            </div>
            <p className="MainBlogContent">{desc}</p>
            <Byline
              author_img_src={author_img_src}
              author_name={author_name}
              date={date}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MainBlog;
