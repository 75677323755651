import { createSlice } from '@reduxjs/toolkit';
import getListRecos from './getListRecos';

const initialState = {
  listRecos: [],
};

export const recoSlice = createSlice({
  name: 'recos',
  initialState,
  reducers: {
    setListRecos: (state, { payload = [] }) => ({
      ...state,
      listRecos: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListRecos.fulfilled, (state, action) => {
      console.log(action);
      return {
        ...state,
        listRecos: action.payload,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const { setListRecos } = recoSlice.actions;

export default recoSlice.reducer;
