import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from './index';

export const getListBlogsSearchAPI = createApi({
  reducerPath: 'listBlogs',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    getListBlogs: builder.query({
      query: () => `/careers/blog/search`,
      transformResponse: (response) => response.jobs,
    }),
    getJobAdsFromBlog: builder.query({
      query: ({ topic, year, slug }) =>
        `/careers/blog-jobs/${topic}/${year}/${slug}`,
      transformResponse: (response) => response?.jobs || [],
    }),
  }),
});

export const getListBlogsAPI = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/blog`,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    return {};
  }
};

export const getMetaDataJobAPI = async (slug) => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/blog-meta/${slug}`,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    return null;
  }
};

export const getListBlogsForHomePageAPI = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/home`,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    return null;
  }
};

export const getContentJobAPI = async (slug) => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/blog/${slug}`,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    return null;
  }
};

export const getContentBlogAPI = async (timestamp) => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/blog-ts/${timestamp}`,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    return null;
  }
};

export const { useGetListBlogsQuery, useLazyGetJobAdsFromBlogQuery } =
  getListBlogsSearchAPI;
