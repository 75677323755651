import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ip: undefined,
  userAgent: undefined,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setSetting: (state, { payload = {} }) => ({
      ...state,
      ...payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setSetting } = settingSlice.actions;

export default settingSlice.reducer;
