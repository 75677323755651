import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import findLastIndex from 'lodash/findLastIndex';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
// import actions
import { setJobAdInfo } from 'redux/jobAd';
import { setSetting } from 'redux/setting';
// import APIs
import { getIPLocal } from 'api/ipLocal';
import 'react-toastify/dist/ReactToastify.css';
import './NavBar.css';

const listInitMenus = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Jobs',
    url: '/jobs',
  },
  {
    name: 'About',
    url: '/about',
  },
  {
    name: 'Blogs',
    url: '/blogs',
  },
];

function NavBar() {
  const { loginWithRedirect, user, isAuthenticated, logout, error } =
    useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [listMenus, setListMenus] = useState(listInitMenus);

  useEffect(() => {
    const getGeneralData = async () => {
      const ip = await getIPLocal();
      const { userAgent } = window.navigator;
      dispatch(setSetting({ ip, userAgent }));
    };

    getGeneralData();
  }, []);

  useEffect(() => {
    if (user?.email) {
      dispatch(setJobAdInfo({ email: user.email }));
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      console.error(error);
      toast.error('There is the error with Auth0', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  }, [error]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname) {
      const index = findLastIndex(
        listMenus,
        (menu) => pathname.indexOf(menu.url) !== -1
      );
      const currentMenu = listMenus[index];
      if (currentMenu) {
        currentMenu.isActive = true;
        setListMenus([
          ...listMenus.map((menu, i) => {
            if (i === index) return menu;
            return {
              ...menu,
              isActive: false,
            };
          }),
        ]);
      }
    }
  }, [pathname]);

  return (
    <Navbar className="nav" collapseOnSelect expand="lg" variant="dark">
      <ToastContainer />
      <Container>
        <Navbar.Brand>
          <div className="nav-logo-container">
            <Link to="/">
              <img src="/nav-logo.svg" alt="Data Skeptic logo" />
            </Link>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          <Nav>
            {listMenus.map((menu) => (
              <Nav.Link key={menu.url}>
                <li
                  className={menu.isActive ? 'aactive' : 'mli'}
                  key={menu.url}
                >
                  <Link className="nav-item" to={menu.url}>
                    {menu.name}
                  </Link>
                </li>
              </Nav.Link>
            ))}
            {isAuthenticated && (
              <>
                <NavDropdown
                  title={user.name || user.email}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item onClick={() => history.push('/ratings')}>
                    Ratings
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => history.push('/subscriptions')}
                  >
                    Subscriptions
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            {!isAuthenticated && (
              <Nav.Link>
                <li className="mli" onClick={() => loginWithRedirect()}>
                  <span>Login</span>
                </li>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
