import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListRatingsAPI } from 'api/rating';

export default createAsyncThunk(
  'rating/getListRating',
  async (data, { rejectWithValue }) => {
    try {
      return await getListRatingsAPI(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
