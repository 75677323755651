import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie-files/beta-testing.json';

function BeforeSearch() {
  return (
    <div className="BeforeSearch">
      <Player autoplay loop src={animationData} className="lottie-animation" />
      <p className="title">
        Welcome to <b>Data Skeptic: Careers</b>.
      </p>
      <p>
        We are currently beta testing our job search engine. Please use the
        search box above.
      </p>
    </div>
  );
}

export default BeforeSearch;
