import axios from './index';

export const getListRatingsAPI = async (username) => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/${username}/rating`,
    });
    return result?.data?.ratings;
  } catch (ex) {
    console.error(ex);
    return {};
  }
};

export const rateUpAPI = async (username, jobId) => {
  try {
    const result = await axios({
      method: 'post',
      url: `/careers/${username}/rating/${jobId}/+1`,
    });
    return result?.data;
  } catch (ex) {
    console.error(ex);
    return {};
  }
};

export const rateDownAPI = async (username, jobId) => {
  try {
    const result = await axios({
      method: 'post',
      url: `/careers/${username}/rating/${jobId}/-1`,
    });
    return result?.data;
  } catch (ex) {
    console.error(ex);
    return {};
  }
};

export const removeRateAPI = async (username, jobId) => {
  try {
    const result = await axios({
      method: 'post',
      url: `/careers/${username}/rating/${jobId}/0`,
    });
    return result?.data;
  } catch (ex) {
    console.error(ex);
    return {};
  }
};

export const jobClickAPI = async (data) => {
  try {
    const result = await axios({
      method: 'post',
      url: '/careers/jobs/click',
      data,
    });
    return result?.data;
  } catch (ex) {
    console.error(ex);
    return {};
  }
};
