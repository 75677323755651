import axios from './index';

export const signupAPI = async (data) => {
  try {
    const result = await axios({
      method: 'post',
      url: `/careers/sign-up`,
      data,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    return {};
  }
};
