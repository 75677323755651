import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import './index.css';

function SubscribeForm({ clientSecret }) {
  const [name, setName] = useState();
  const [messages, _setMessages] = useState('');
  const [isLoading, setIsloading] = useState(false);

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${message}`);
  };

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        case 'requires_source':
          setMessage('Input payment details');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, clientSecret, elements]);

  const confirmPayment = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const return_url =
      window.location.hostname === 'localhost'
        ? `http://${window.location.host}/checkout/thank-you`
        : `https://${window.location.host}/checkout/thank-you`;

    setIsloading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url,
      },
    });

    console.log(error);

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }
  };

  return (
    <div className="subscription-stripe-form">
      {messages && <Alert color="primary">{messages}</Alert>}
      <form onSubmit={confirmPayment}>
        <FormGroup>
          <Label for="name">Full name</Label>
          <Input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <br />
        <FormGroup>
          <Label for="cardStrip">Card infomation</Label>
          {clientSecret && <PaymentElement />}
        </FormGroup>

        <FormGroup className="submit-stripe-btn">
          <Button
            disabled={isLoading || !stripe || !elements}
            color="primary"
            type="submit"
            onClick={confirmPayment}
          >
            Subscribe
          </Button>
        </FormGroup>
      </form>
    </div>
  );
}

export default SubscribeForm;
