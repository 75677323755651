import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  // <!--
  // <p className="footer-link-p"><Link className="footer-link" to="/about-us">About</Link></p>
  // -->
  return (
    <div className="footer">
      <Container>
        <Row className="d-flex justify-content-between">
          <Col xs="6" sm="4">
            <div className="footer-p">
              <div>© ALL RIGHTS RESERVED</div>
              <div>Terms and Conditions</div>
            </div>
          </Col>
          <Col xs="6" sm="8" className="d-flex list-links">
            <p className="footer-link-p">
              <Link className="footer-link" to="/checkout/create-ad">
                Post a Job Ad
              </Link>
            </p>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://dataskeptic.com/contact-us"
              >
                Contact
              </a>
            </p>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://dataskeptic.com/advertising"
              >
                Advertising
              </a>
            </p>
            <p className="footer-link-p">
              <a
                className="footer-link"
                href="https://dataskeptic.com/privacy-policy"
              >
                Privacy Policy
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
