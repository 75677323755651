export const jobTypeOptions = [
  { label: 'Full-Time', value: 'full-time' },
  { label: 'Part-Time', value: 'part-time' },
  { label: 'Contract', value: 'contract' },
];

export const remoteOptions = [
  { label: 'On-site only', value: 'on-site' },
  { label: 'Fully Remote', value: 'remote' },
  { label: 'Remote, transitioning on-site', value: 'temp-remote' },
];

export const products = [
  {
    sku: 'small',
    title: 'Basic Listing',
    bullets: ['Displayed with Logo', 'Email Analytics'],
    duration: '$75 for 2 weeks',
    price: 75,
    lookupKey: 'basic_listing',
    price_id: 'careers_weekly',
  },
  {
    sku: 'medium',
    title: 'Preferred Listing',
    bullets: ['Prioritized listing', 'Highlighted Ad'],
    duration: '$125 for 4 weeks',
    price: 125,
    lookupKey: 'preferred_listing',
    price_id: 'careers_weekly',
  },
  {
    sku: 'large',
    title: 'Top Placement',
    bullets: ['Guaranteed Placement', 'Mention on Data Skeptic podcast'],
    duration: '$200 for 4 weeks',
    price: 200,
    lookupKey: 'top_placement',
    price_id: 'careers_weekly',
  },
];

export const prices = {
  small: 59,
  medium: 99,
  large: 149,
};
