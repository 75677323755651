import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListRecomendationsAPI } from 'api/job';

export default createAsyncThunk(
  'jobs/getListRecos',
  async (query, { rejectWithValue }) => {
    try {
      return await getListRecomendationsAPI();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
