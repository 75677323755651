import React from 'react';
import { Carousel } from '@trendyol-js/react-carousel';
import PostCompanyImg1 from 'assets/images/post-company-1.png';
import PostCompanyImg2 from 'assets/images/post-company-2.png';
import PostCompanyImg3 from 'assets/images/post-company-3.png';
import PostCompanyImg4 from 'assets/images/post-company-4.png';

const ListCompanyImages = () => (
  <div className="company-images-carousel">
    <div className="title">Company images</div>
    <Carousel show={3.5} slide={3} swiping responsive>
      <div className="image">
        <img src={PostCompanyImg1} alt="" />
      </div>
      <div className="image">
        <img src={PostCompanyImg2} alt="" />
      </div>
      <div className="image">
        <img src={PostCompanyImg3} alt="" />
      </div>
      <div className="image">
        <img src={PostCompanyImg4} alt="" />
      </div>
    </Carousel>
  </div>
);

export default ListCompanyImages;
