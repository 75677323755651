import React from 'react';
import PricingItemItem from './PricingItemItem';
import './PricingItem.css';

function PricingItem({ item }) {
  const { title, price, clazz, features, missing } = item;
  const has_feature = true;
  const featurez = features.map((feature) => (
    <PricingItemItem label={feature} has_feature={has_feature} />
  ));
  const missingz = missing.map((feature) => (
    <PricingItemItem label={feature} />
  ));
  const cn = `card ${clazz} mb-5 mb-lg-0 rounded-lg shadow`;
  return (
    <div className="PricingItem">
      <div className={cn}>
        <div className="card-header">
          <h5 className="card-title text-white-50 text-uppercase text-center">
            {title}
          </h5>
          <h6 className="h1 text-white text-center">
            {price}
            <span className="h6 text-white-50">/month</span>
          </h6>
        </div>
        <div className="card-body bg-light rounded-bottom">
          <ul className="list-unstyled mb-4">
            {featurez}
            {missingz}
          </ul>
          <a
            href="#"
            className="btn btn-block btn-primary text-uppercase rounded-lg py-3"
          >
            Buy now
          </a>
        </div>
      </div>
    </div>
  );
}

export default PricingItem;
