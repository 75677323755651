import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListJobsAPI } from 'api/job';

export default createAsyncThunk(
  'jobs/getListJobs',
  async (query, { rejectWithValue }) => {
    try {
      return await getListJobsAPI(query);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
