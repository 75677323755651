import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from './index';

export const getListJobsAPI = createApi({
  reducerPath: 'listJobs',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    getListJobs: builder.query({
      query: (query) => `/careers/jobs/search?q=${query}`,
      transformResponse: (response) => response.jobs,
    }),
  }),
});

export const getJobDetailAPI = async (jobId) => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/jobs/${jobId}`,
    });
    return result?.data;
  } catch (ex) {
    console.log(ex);
    return null;
  }
};

export const jobTrackingAPI = async (job_id) => {
  try {
    const result = await axios({
      method: 'post',
      url: `/careers/jobs/click`,
      data: {
        job_id,
      },
    });
    return result?.data;
  } catch (ex) {
    console.error(ex);
    return {};
  }
};

export const getListRecomendationsAPI = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: `/careers/jobs/search-recommendations`,
    });
    return result?.data?.recos;
  } catch (err) {
    console.error(err);
    return [];
  }
};
export const { useLazyGetListJobsQuery } = getListJobsAPI;
