import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import { getListJobsAPI } from 'api/job';
import { getListBlogsSearchAPI } from 'api/blog';
import { getSubscriptionsAPI } from 'api/subscription';
import rating from './rating';
import jobAd from './jobAd';
import jobs from './jobs';
import setting from './setting';
import recos from './recommedations';

const reducers = combineReducers({
  rating,
  jobAd,
  jobs,
  setting,
  recos,
  [getListJobsAPI.reducerPath]: getListJobsAPI.reducer,
  [getListBlogsSearchAPI.reducerPath]: getListBlogsSearchAPI.reducer,
  [getSubscriptionsAPI.reducerPath]: getSubscriptionsAPI.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage('dataskeptic'),
  whitelist: [
    'recos',
    'rating',
    'jobs',
    'setting',
    'recos',
    'listJobs',
    'listBlogs',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(getListJobsAPI.middleware)
      .concat(getSubscriptionsAPI.middleware),
});

export const persistor = persistStore(store);
