import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Blogs } from 'blogs';
import Footer from './common/Footer';
import Header from './common/Header';
import NavBar from './navbar/NavBar';
import NotFound from './common/NotFound';
import Blog from './blog/Blog';
import BlogPage from './blog/BlogPage';
import BlogDetail from './blog/BlogDetail';
import Checkout from './checkout/Checkout';
import ThankYou from './checkout/ThankYou';
import Pricing from './pricing/Pricing';
import CreateAdForm from './checkout/CreateAdForm';
import Preview from './checkout/Preview';
import Jobs from './jobs/Jobs';
import JobDetail from './JobDetail';
import About from './About';
import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from './Homepage';
import Ratings from './Ratings';
import Subscriptions from './Subscriptions';
import './App.css';
// import ReactGA from 'react-ga';

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Switch>
          <Route exact path="/">
            <div className="App">
              <Homepage />
            </div>
          </Route>
          <Route exact path="/jobs">
            <div className="App">
              <Jobs />
            </div>
          </Route>
          <Route exact path="/subscriptions">
            <div className="App">
              <Subscriptions />
            </div>
          </Route>
          <Route exact path="/ratings">
            <div className="App">
              <Ratings />
            </div>
          </Route>
          <Route exact path="/jobs/:jobId">
            <div className="App">
              <JobDetail />
            </div>
          </Route>
          <Route exact path="/blog">
            <div className="App">
              <Header />
              <Blog />
            </div>
          </Route>
          <Route exact path="/blog/:blogSlug">
            <div className="App">
              <BlogDetail />
            </div>
          </Route>
          <Route exact path="/blog/:topic/:year/:slug">
            <div className="App">
              <BlogPage />
            </div>
          </Route>
          <Route exact path="/about">
            <div className="App">
              <About />
            </div>
          </Route>
          <Route exact path="/checkout/create-ad">
            <div className="App">
              <Header />
              <CreateAdForm />
            </div>
          </Route>
          <Route exact path="/checkout/preview">
            <div className="App">
              <Header />
              <Preview />
            </div>
          </Route>
          <Route exact path="/checkout/final">
            <div className="App">
              <Header />
              <Checkout />
            </div>
          </Route>
          <Route exact path="/checkout/thank-you">
            <div className="App">
              <Header />
              <ThankYou />
            </div>
          </Route>
          <Route exact path="/blogs">
            <div className="App">
              <Header />
              <Blogs />
            </div>
          </Route>
          <Route exact path="/pricing">
            <div className="App">
              <Pricing />
            </div>
          </Route>
          <Route path="*">
            <Header />
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
