import * as localForage from 'localforage';

export const getItem = async (key) => {
  const data = await localForage.getItem(key);

  if (!data) return data;

  const { expire, value } = data;

  if (expire && expire < Date.now()) {
    localForage.removeItem(key);
    return null;
  }

  return value;
};

export const setItem = async (key, value, expire = false) => {
  if (expire && typeof expire === 'number') {
    // eslint-disable-next-line
    expire = Math.round(expire * 1000 + Date.now()); // * 1000 to use seconds
  }

  await localForage.setItem(key, { value, expire });
};
