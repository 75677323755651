import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import replace from 'lodash/replace';
import { useHistory } from 'react-router-dom';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import actions
import { setJobAdInfo } from 'redux/jobAd';
import { remoteOptions, jobTypeOptions, products } from 'constants/job';
// import APIs
import { getListPricingAPI } from 'api/subscription';
// import components
import SelectField from '../../common/SelectField';
import EditorField from '../../common/EditorField';
import Progress from '../Progress';
import './index.css';

const CreateAdForm = () => {
  const [listPricings, setListPricings] = useState([]);
  const [product, setProduct] = useState(listPricings[0]);
  const history = useHistory();
  const dispatch = useDispatch();
  const adInfo = useSelector((state) => state.jobAd.adInfo);
  const { user } = useAuth0();

  useEffect(() => {
    const getListPricings = async () => {
      const data = await getListPricingAPI();
      setListPricings(data);
    };

    getListPricings();
  }, []);

  useEffect(() => {
    if (adInfo?.price) {
      const prod = find(products, (p) => p.price === adInfo?.price);
      if (prod) setProduct(prod);
    }
  }, [adInfo]);

  return (
    <div className="create-ad-form container">
      <Helmet>
        <title>Create a Paid Listing</title>
      </Helmet>
      <Progress stage="create-ad" />
      <div className="create-ad-details">
        <br />
        <h2>Position Details</h2>

        <Formik
          initialValues={{
            ...adInfo,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if (!values.jobTitle) errors.jobTitle = 'Required';

            if (!values.companyName) errors.companyName = 'Required';

            if (!values.headquarters) errors.headquarters = 'Required';

            if (!values.applicationLink) errors.applicationLink = 'Required';
            else {
              const link = replace(values.applicationLink, /(^\w+:|^)\/\//, '');
              if (!link) errors.applicationLink = 'Required';
            }

            if (!values.url) errors.url = 'Required';

            return errors;
          }}
          onSubmit={(values) => {
            dispatch(setJobAdInfo({ ...values, price_id: product?.price_id }));
            history.push('/checkout/preview');
          }}
        >
          {({ errors, touched, submitForm, isValid }) => (
            <Form autoComplete="off">
              <FormGroup row className="mb-3">
                <Label for="email" sm={3}>
                  Primary Contact Email
                  <span aria-hidden="true" className="required">
                    *
                  </span>
                </Label>
                <Col sm={9}>
                  <Input
                    id="email"
                    name="email"
                    tag={Field}
                    invalid={errors.email && touched.email}
                    autoComplete="off"
                    disabled={user}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-3">
                <Label for="job-title" sm={3}>
                  Job Title
                  <span aria-hidden="true" className="required">
                    *
                  </span>
                </Label>
                <Col sm={9}>
                  <Input
                    id="job-title"
                    name="jobTitle"
                    tag={Field}
                    invalid={errors.jobTitle && touched.jobTitle}
                  />
                  <ErrorMessage
                    name="jobTitle"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-3">
                <Label for="headquarters" sm={3}>
                  Headquarters
                </Label>
                <Col sm={9}>
                  <Input
                    id="headquarters"
                    name="headquarters"
                    tag={Field}
                    invalid={errors.headquarters && touched.headquarters}
                  />
                  <ErrorMessage
                    name="headquarters"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-3">
                <Label for="remote" sm={3}>
                  Remote?
                </Label>
                <Col sm={9}>
                  <Field
                    id="remote"
                    name="remote"
                    component={SelectField}
                    options={remoteOptions}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-3">
                <Label for="job-type" sm={3}>
                  Job Type
                </Label>
                <Col sm={9}>
                  <Field
                    id="job-type"
                    name="jobType"
                    component={SelectField}
                    options={jobTypeOptions}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-3">
                <Label for="application-link" sm={3}>
                  Application Link
                </Label>
                <Col sm={9}>
                  <Input
                    id="application-link"
                    name="applicationLink"
                    tag={Field}
                    invalid={errors.applicationLink && touched.applicationLink}
                  />
                  <ErrorMessage
                    name="applicationLink"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-3">
                <Label for="job-description" sm={3}>
                  Job Description
                </Label>
                <Col sm={9}>
                  <div className="jobDescContainer">
                    <Field
                      id="job-description"
                      name="jobDescription"
                      component={EditorField}
                    />
                  </div>
                </Col>
              </FormGroup>

              <hr />

              <h2>Company Details</h2>

              <FormGroup row className="mb-3">
                <Label for="company-name" sm={3}>
                  Company Name
                </Label>
                <Col sm={9}>
                  <Input
                    id="company-name"
                    name="companyName"
                    tag={Field}
                    invalid={errors.companyName && touched.companyName}
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-3">
                <Label for="comany-url" sm={3}>
                  Company URL
                </Label>
                <Col sm={9}>
                  <Input
                    id="comany-url"
                    name="url"
                    tag={Field}
                    invalid={errors.url && touched.url}
                  />
                  <ErrorMessage
                    name="url"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-3">
                <Label for="comany-description" sm={3}>
                  Company Description
                </Label>
                <Col sm={9}>
                  <div className="jobDescContainer">
                    <Field
                      id="comany-description"
                      name="companyDescription"
                      component={EditorField}
                    />
                  </div>
                </Col>
              </FormGroup>

              <hr />

              <div className="BottomContainer">
                <div className="Bottom">
                  <div
                    className="nextButtonContainer"
                    onClick={submitForm}
                    role="button"
                  >
                    Preview Ad &nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              {!isValid && (
                <div className="create-ads-error">
                  Please fill all inputs above
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateAdForm;
