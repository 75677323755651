import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie-files/not-found.json';

function NoResults(props) {
  const { query } = props;
  return (
    <div className="SearchResults no-result-section justify-content-center">
      <Player autoplay loop src={animationData} className="lottie-animation" />
      <h1 className="title">No results found</h1>
      <p>
        Could not find any jobs relevant for{' '}
        <b>
          <i>{query}</i>
        </b>
        .
      </p>
      <p>Please try searching something else.</p>
    </div>
  );
}

export default NoResults;
