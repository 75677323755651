import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import chunk from 'lodash/chunk';
import dayjs from 'dayjs';
// impo{rt actions
import { getListBlogsForHomePageAPI } from 'api/blog';

const LatestBlog = ({ blogs }) => {
  const [loaded, setLoaded] = useState(false);
  const [latestBlogs, setLatestBlogs] = useState([]);

  useEffect(() => {
    const getListBlogs = async () => {
      const listBlogs = await getListBlogsForHomePageAPI();
      setLatestBlogs(chunk(listBlogs?.articles || [], 2));
      setLoaded(true);
    };
    getListBlogs();
  }, []);
  if (!loaded) {
    return <div className="latest-blog-section container">Loading...</div>;
  }
  return (
    <div className="latest-blog-section container">
      {latestBlogs.map((listBlogs) => (
        <div className="blog-row d-flex">
          {(blogs || listBlogs).map((blog) => (
            <>
              <div key={blog?.title} className="blog-col d-flex">
                <div className="blog-info">
                  <div className="title">
                    <Link to={`/blog/${blog.path}-${blog.timestamp}`}>
                      {blog?.title}
                    </Link>
                  </div>
                  <div className="description">{blog?.abstract}</div>
                  <div className="blog-owner d-flex">
                    <div className="owner-info">
                      {blog?.authors.map((author) => (
                        <div key={blog?.title}>
                          <div className="avatar">
                            <img
                              className="avatar-image"
                              src={author?.image_url}
                              alt={author?.name}
                            />
                          </div>
                          <div key={author?.slug} className="name">
                            {author?.name}
                          </div>
                        </div>
                      ))}
                      {blog?.timestamp && (
                        <div className="time">
                          {dayjs(blog?.timestamp).format('MM/DD/YYYY')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="blog-thumbnail">
                  <img
                    className="bt-img"
                    src={blog?.main_image}
                    alt={blog?.title}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export default LatestBlog;
