import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useInitializeCheckoutMutation } from 'api/subscription';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Loading from 'common/Loading';
import { toast } from 'react-toastify';
import Progress from './Progress';
import SubscribeForm from './SubscribeForm';
import './Checkout.css';

function Checkout() {
  const [stripePromise, setStripePromise] = useState(null);
  const location = useLocation();
  const [initializeCheckout, { data, error }] = useInitializeCheckoutMutation();
  const [clientSecret, setClientSecret] = useState('');
  const amount = location?.product?.amount;
  useEffect(() => {
    initializeCheckout(amount);
  }, []);
  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
  }, []);

  useEffect(() => {
    if (data) {
      setClientSecret(data?.client_secret);
    }
  }, [data]);

  if (error) {
    toast.error(error.data.error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }

  if (!clientSecret) return <Loading />;
  return (
    <div className="Checkout">
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      <Progress stage="checkout" />
      <div className="CheckoutDetails">
        <h1>Checkout</h1>
        <p>
          Your ad will be reviewed and added to our index within 1 business day.
        </p>
        <p>
          For questions or comments, please reach out to{' '}
          <a href="mailto:support@dataskeptic.com">support@dataskeptic.com</a>.
        </p>

        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <SubscribeForm clientSecret={clientSecret} />
        </Elements>
      </div>
    </div>
  );
}

export default Checkout;
