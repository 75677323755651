import React from 'react';
import { useGetSubscriptionDataQuery } from 'api/subscription';
import FreeJobPost from 'JobPost/Free';
import EnhancedAndFeatured from '../EnhancedAndFeatured';
import './index.css';

const JobPost = ({ data, index, query, incomingLayout, url }) => {
  const { data: subData } = useGetSubscriptionDataQuery();
  const layout = (subData && subData[0] && subData[0].layout) || 'free';
  const renderLayout = incomingLayout || layout;
  const renderJobPost = () => {
    switch (renderLayout) {
      case 'free':
        return (
          <FreeJobPost data={data} index={index} query={query} imgUrl={url} />
        );
      case 'basic':
        return (
          <FreeJobPost
            data={data}
            index={index}
            query={query}
            imgUrl={url}
            isBasic
          />
        );
      case 'enhanced':
        return (
          <EnhancedAndFeatured
            data={data}
            index={index}
            imgUrl={url}
            query={query}
          />
        );
      case 'featured':
        return (
          <EnhancedAndFeatured
            data={data}
            index={index}
            query={query}
            imgUrl={url}
            isFeatured
          />
        );
      default:
        return (
          <FreeJobPost data={data} index={index} query={query} imgUrl={url} />
        );
    }
  };
  return <div>{renderJobPost()}</div>;
};

export default JobPost;
