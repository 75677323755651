import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import redux actions
import getListRatingAction from 'redux/rating/getListRatingAction';
import SearchResults from '../search/SearchResults';
import './Jobs.css';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Jobs() {
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth0();
  const [query, setQuery] = useState('');
  const q = queryParams.get('q');

  useEffect(() => {
    if (isAuthenticated) dispatch(getListRatingAction(user?.email));
  }, [isAuthenticated]);

  useEffect(() => {
    setQuery(q);
  }, [q]);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };
  const ptitle = 'Career search by Data Skeptic';
  return (
    <div className="HomeCenter">
      <Helmet>
        <title>{ptitle}</title>
      </Helmet>
      <div className="header-search">
        <div className="container">
          <div className="input-groups">
            <input
              type="text"
              className="form-control input-text"
              placeholder="Search by job, company"
              aria-label="search"
              aria-describedby="basic-addon2"
              value={query}
              /* eslint-disable */
              autoFocus
              onChange={handleQueryChange}
            />
            <button className="btn search-btn" type="button">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
      </div>
      <div className="container list-jobs-section">
        <SearchResults query={query} />
      </div>
    </div>
  );
}

export default Jobs;
