import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ReactComponent as SuccessIcon } from 'assets/icons/check-circle-fill.svg';
import './index.css';

const SuccessPopup = ({ isShow, onClose }) => (
  <Modal className="modal-newsletter success-newsletter" show={isShow}>
    <Modal.Header>
      <SuccessIcon className="success-icon" />
      <h4>Success</h4>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-hidden="true"
        onClick={onClose}
      >
        <span>×</span>
      </button>
    </Modal.Header>
    <Modal.Body className="text-center">
      <p>
        Thank you for subscribing. Your newsletter will be delivered shortly to
        the email address provided.
      </p>
      <br />
      <Button onClick={onClose}>Close</Button>
    </Modal.Body>
  </Modal>
);

export default SuccessPopup;
