import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useGetSubscriptionDataQuery } from 'api/subscription';
import Loading from 'common/Loading';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import {
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  FormText,
  FormFeedback,
} from 'reactstrap';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import api
import PreviewJob from './PreviewJob';
import Progress from './Progress';
import './Preview.css';

function Preview() {
  const jobAd = useSelector((state) => state.jobAd.adInfo);
  const history = useHistory();
  const { data: listPricings } = useGetSubscriptionDataQuery();
  const [product, setProduct] = useState(listPricings && listPricings[0]);
  const [errorMessage, setErrorMessage] = useState('');
  const [urlValue, setUrlValue] = useState('');

  const handleBlur = (value) => {
    if (validator.isURL(value) || !value) {
      setErrorMessage(null);
    } else {
      setErrorMessage('Invalid URL');
    }
  };

  const handleChange = (event) => {
    setUrlValue(event.target.value);
    handleBlur(event.target.value);
  };

  const handleClickPick = (slug) => {
    const prod = find(listPricings, (p) => p.object_id === slug);
    if (prod) setProduct(prod);
  };

  const onCheckout = async () => {
    history.push({
      pathname: '/checkout/final',
      product: product || listPricings[0],
    });
  };
  if (!listPricings) return <Loading />;

  return (
    <div className="Preview">
      <Helmet>
        <title>Preview a Paid Listing</title>
      </Helmet>
      <Progress stage="preview" />
      <div className="Details">
        <h1>Preview</h1>
        <Row>
          {listPricings?.map((prod, index) => (
            <Col>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="job-ad"
                    defaultChecked={index === 0}
                    onChange={() => handleClickPick(prod.object_id)}
                  />{' '}
                  {prod?.label} {`$${prod?.amount}`}
                </Label>
              </FormGroup>
            </Col>
          ))}
          <FormGroup>
            <Label for="imageUrl" className="img-label">
              Image URL
            </Label>
            <br />
            <Input
              type="url"
              name="url"
              id="imageUrl"
              placeholder="Optional"
              value={urlValue}
              invalid={!validator.isURL}
              onChange={handleChange}
              onBlur={(event) => handleBlur(event.target.value)}
              pattern="https://.*"
            />
            <p className="form-error-message">{errorMessage}</p>
            <FormFeedback valid>Optional</FormFeedback>
            <FormText color="muted">
              If no image is placed, our team will reach out via email to
              coordinate directly.
            </FormText>
          </FormGroup>
        </Row>
        <br />
        <div className="job-section">
          <PreviewJob
            data={jobAd}
            layout={product?.layout}
            url={validator.isURL(urlValue) ? urlValue : ''}
          />
        </div>
        <div className="BottomContainer">
          <div className="Bottom">
            <div
              className="nextButtonContainer"
              onClick={onCheckout}
              role="presentation"
            >
              Finalize &nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
