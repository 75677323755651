import React from 'react';
import './Byline.css';

function Byline(props) {
  const { author_img_src } = props;
  const { author_name: author, date } = props;
  return (
    <div className="Byline">
      <div className="BylineLeft">
        <img className="AuthorImageSmall" src={author_img_src} alt="" />
      </div>
      <div className="BylineRight">
        <div className="AuthorName">{author}</div>
        <div className="BlogDate">{date}</div>
      </div>
    </div>
  );
}

export default Byline;
