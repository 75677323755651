import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';

const selectRatings = (state) => state.rating.listRatings;

export const selectRatingById = createSelector(selectRatings, (listRatings) =>
  memoize((ratingId) =>
    listRatings?.find((rating) => rating.job_id === ratingId)
  )
);
