import { createSlice } from '@reduxjs/toolkit';
import { remoteOptions, jobTypeOptions } from 'constants/job';

const initialState = {
  adInfo: {
    email: '',
    jobTitle: '',
    headquarters: '',
    remote: remoteOptions?.[0]?.value,
    jobType: jobTypeOptions?.[0]?.value,
    applicationLink: 'https://',
    jobDescription: '',
    companyName: '',
    url: '',
    logo: '',
    companyDescription: '',
  },
};

export const jobAdSlice = createSlice({
  name: 'jobAd',
  initialState,
  reducers: {
    setJobAdInfo: (state, { payload = {} }) => ({
      ...state,
      adInfo: { ...state.adInfo, ...payload },
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setJobAdInfo } = jobAdSlice.actions;

export default jobAdSlice.reducer;
