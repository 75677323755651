import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Input, Button, Badge } from 'reactstrap';
import btmImg from 'assets/images/homepage-banner-bottom.png';
import bannerImg from 'assets/images/homepage-image.png';
import getListRecosAction from 'redux/recommedations/getListRecos';
import LatestBlog from './LatestBlog';
import NewsletterPopup from './NewsletterPopup';
import './index.css';

function HomeCenter() {
  const history = useHistory();
  const [textSearch, setTextSearch] = useState('');
  const ptitle = 'Career search by Data Skeptic';
  const dispatch = useDispatch();
  const recos = useSelector((state) => state.recos.listRecos);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const input = document.getElementById('search-banner');
    input.focus();
  }, []);

  const onSearchChange = (event) => {
    setTextSearch(event.target.value);
  };

  useEffect(() => {
    dispatch(getListRecosAction());
  }, []);

  useEffect(() => {
    setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === recos.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
  }, []);

  const onSearchInput = (event) => {
    if (event.charCode === 13 && textSearch) {
      history.push(`/jobs?q=${textSearch}`);
    }
  };

  const onSearchBtn = () => {
    if (textSearch) history.push(`/jobs?q=${textSearch}`);
  };

  return (
    <div className="homepage">
      <NewsletterPopup />
      <Helmet>
        <title>{ptitle}</title>
      </Helmet>
      <div className="homepage-banner-section">
        <div className="d-flex container">
          <Col xs="5" className="content-section">
            <div className="title">
              <div>Data jobs for data professionals</div>
              <div>Find your next opportunity</div>
            </div>
            <div className="description">
              We crawl, import, aggregate, and curate job listings of interest
              to data professionals. Whether you're a business analyst or a
              machine learning engineer, we hope you find roles of interest in
              our system.
            </div>
            <div className="find-section">
              <div className="titl">What role are you looking for?</div>

              <div className="find-action d-flex">
                <Input
                  id="search-banner"
                  name="search"
                  placeholder="Search by job title or company"
                  autoFocus
                  onKeyPress={onSearchInput}
                  onChange={onSearchChange}
                  value={textSearch}
                />

                <Button onClick={onSearchBtn}>Find</Button>
              </div>
              {recos.length ? (
                <div className="reco-container">
                  <Badge
                    color="dark"
                    pill
                    onClick={() => {
                      setTextSearch(recos[index]);
                    }}
                  >
                    {recos[index]}
                  </Badge>
                </div>
              ) : null}
            </div>
          </Col>
        </div>
        <img src={bannerImg} className="banner-img" alt="" />
        <img src={btmImg} className="bottom-effect" alt="" />
      </div>
      <LatestBlog />
    </div>
  );
}

export default HomeCenter;
