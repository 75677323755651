import React from 'react';
// import components
import { FreeAndBasicPostJob } from 'JobPost';
import './Job.css';

function Job({ jobInfo, index, query, layout }) {
  return (
    <>
      <FreeAndBasicPostJob
        data={jobInfo}
        index={index}
        query={query}
        incomingLayout={layout || 'free'}
      />
    </>
  );
}

export default Job;
