import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { FormGroup, Input } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { getItem, setItem } from 'utils/storage';
import { signupAPI } from 'api/auth';
import SuccessPopup from './SuccessPopup';
import './index.css';

const NewsletterPopup = () => {
  const [isShow, setIsShow] = useState(false);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);

  const onScroll = useCallback(() => {
    if (window.pageYOffset) setIsShow(true);
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      const isSubscribed = await getItem('isSubscribed');
      if (!isSubscribed) {
        setTimeout(() => {
          // clean up code
          window.removeEventListener('scroll', onScroll);
          window.addEventListener('scroll', onScroll, { passive: true });
        }, 20 * 1000);
      }
    };

    handleScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onClose = async (e) => {
    if (e) e.preventDefault();

    await setItem('isSubscribed', true, 7 * 24 * 60 * 60);
    window.removeEventListener('scroll', onScroll);
    setIsShow(false);
  };

  return (
    <>
      <Modal className="modal-newsletter" show={isShow}>
        <Modal.Header>
          <h4>Join Our Newsletter</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={onClose}
          >
            <span>×</span>
          </button>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>
            Sign up for our email newseltter for data science career content and
            opportunitites
          </p>

          <Formik
            initialValues={{
              email: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }

              return errors;
            }}
            onSubmit={async (values) => {
              await signupAPI(values);
              await setItem('isSubscribed', true);
              window.removeEventListener('scroll', onScroll);
              setIsShowSuccessPopup(true);
              setIsShow(false);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <FormGroup row className="mb-3">
                  <Input
                    id="email"
                    name="email"
                    tag={Field}
                    invalid={errors.email && touched.email}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </FormGroup>
                <FormGroup row className="mb-3">
                  <input
                    type="submit"
                    className="btn btn-primary btn-block"
                    value="Subscribe"
                  />
                </FormGroup>
              </Form>
            )}
          </Formik>
          <div className="footer-link">
            <a href="#" onClick={onClose}>
              No Thanks
            </a>
          </div>
        </Modal.Body>
      </Modal>

      <SuccessPopup
        isShow={isShowSuccessPopup}
        onClose={() => setIsShowSuccessPopup(false)}
      />
    </>
  );
};

export default NewsletterPopup;
