import { useFinalizeCheckoutMutation } from 'api/subscription';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import './ThankYou.css';

function ThankYou() {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const stripeId = query.get('payment_intent');
  const [finalizeCheckout] = useFinalizeCheckoutMutation();
  useEffect(() => {
    if (stripeId) {
      finalizeCheckout(stripeId);
    }
  }, [stripeId]);
  return (
    <div className="Checkout">
      <Helmet>
        <title>Thank you</title>
      </Helmet>
      <div className="CheckoutDetails">
        <h1>Thank you</h1>
        <p>
          Your ad will be reviewed and added to our index within 1 business day.
        </p>
        <p>
          For questions or comments, please reach out to{' '}
          <a href="mailto:support@dataskeptic.com">support@dataskeptic.com</a>.
        </p>
      </div>
    </div>
  );
}

export default ThankYou;
