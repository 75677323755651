import { createSlice } from '@reduxjs/toolkit';
import getListRatingAction from './getListRatingAction';

const initialState = {
  listRatings: [],
};

export const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {
    setListRatings: (state, { payload = {} }) => ({
      ...state,
      listRatings: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListRatingAction.fulfilled, (state, action) => ({
      ...state,
      listRatings: action.payload,
    }));
  },
});

// Action creators are generated for each case reducer function
export const { setListRatings } = ratingSlice.actions;

export default ratingSlice.reducer;
