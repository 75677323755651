import React, { useState, useEffect } from 'react';
// import components
import { FreeAndBasicPostJob } from 'JobPost';
import NoData from './NoData';

function Job({ data, layout, url }) {
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    if (data) {
      const { jobTitle, companyName, jobDescription, jobType, ...tempData } =
        data;

      setJobData({
        key: 'creating-ads',
        title: jobTitle,
        company_name: companyName,
        desc: jobDescription,
        job_type: jobType,
        updated_at: Math.floor(Date.now() / 1000),
        ...tempData,
      });
    } else {
      setJobData(null);
    }
  }, [data]);

  if (!jobData) return <NoData />;

  return (
    <FreeAndBasicPostJob
      data={jobData}
      index={0}
      query=""
      incomingLayout={layout}
      url={url}
    />
  );
}

export default Job;
