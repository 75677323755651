import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import APIs
import { sendMsgAPI } from 'api/contactUs';
import './About.css';

function About() {
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <nav className="About">
      <div className="banner">
        <div className="title">About us</div>
      </div>
      <div className="container">
        <div className="title">Data jobs for data professionals</div>
        <div className="content">
          <p>
            Data Skeptic is an educational podcast covering a variety of topics
            in data science, machine learning, statistics, and artificial
            intelligence. We started this site to help our listeners find
            opportunities.
          </p>
          <p>
            Our site is a work in progress. We are actively improving our
            interface and algorithms. We launched in late 2022 and we
            occasionally report about our work on this site via our{' '}
            <a href="https://dataskeptic.com">data science podcast</a>. If you
            are curious about how we run the site, tune in!
          </p>
        </div>
        <div className="contact-section">
          <Row>
            <Col md="8" sm="12" className="contact-info">
              <div className="title">Arbitrarily based in</div>
              <div className="address">Los Angeles, CA USA</div>
              <div className="email">Email: support@dataskeptic.com</div>
            </Col>
            <Col md="4" sm="12" className="contact-form">
              <div className="title">Contact Us</div>
              <Formik
                initialValues={{ email: '', name: '', message: '' }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = 'Required';
                  }

                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'Invalid email address';
                  }

                  if (!values.message) {
                    errors.message = 'Required';
                  }

                  return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await sendMsgAPI(values);
                    resetForm();
                    setIsError(false);
                    setisSuccess(true);
                  } catch (ex) {
                    setisSuccess(false);
                    setIsError(true);
                  }
                  setSubmitting(false);
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <FormGroup>
                      <Label for="contactName">Name</Label>
                      <Input
                        id="contactName"
                        name="name"
                        tag={Field}
                        invalid={errors.name && touched.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <br />
                    <FormGroup>
                      <Label for="contactEmail">Email</Label>
                      <Input
                        id="contactEmail"
                        name="email"
                        tag={Field}
                        invalid={errors.email && touched.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <br />
                    <FormGroup>
                      <Label for="contactMessage">Message</Label>
                      <Input
                        id="contactMessage"
                        name="message"
                        as="textarea"
                        tag={Field}
                        invalid={errors.message && touched.message}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <br />
                    {isSuccess && (
                      <Alert>
                        Thanks for your feedback. Someone from our support team
                        will get back to you soon
                      </Alert>
                    )}
                    {isError && (
                      <Alert color="danger">
                        Unable to send your request. Please try again later
                      </Alert>
                    )}
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </div>
    </nav>
  );
}

export default About;
