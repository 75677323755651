import React from 'react';
import format from 'date-fns/format';
import renderHTML from 'react-render-html';
import { useDispatch, useSelector } from 'react-redux';
import { selectRatingById } from 'redux/rating/selector';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';
import {
  faThumbsDown as faThumbsDownActive,
  faThumbsUp as faThumbsUpActive,
} from '@fortawesome/free-solid-svg-icons';
import { jobClickAPI } from 'api/rating';
import getListRatingAction from 'redux/rating/getListRatingAction';
import LogoImg from 'assets/images/logo-bg-new-post.png';
import ListCompanyImages from './ListCompanyImages';
import './index.css';

const JobPost = ({
  isFeatured = false,
  data,
  isDemo = false,
  index,
  query,
  imgUrl,
}) => {
  const { isAuthenticated, user } = useAuth0();
  const settingInfo = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const onLike = (id) => async () => {
    await jobClickAPI({
      ...settingInfo,
      logged_in: isAuthenticated,
      logged_in_email: user?.email,
      search_term: query,
      rank: index,
      favorite: 1,
      job_id: id,
    });
    dispatch(getListRatingAction(user?.email));
  };

  const onDislike = (id) => async () => {
    await jobClickAPI({
      ...settingInfo,
      logged_in: isAuthenticated,
      logged_in_email: user?.email,
      search_term: query,
      rank: index,
      favorite: -1,
      job_id: id,
    });
    dispatch(getListRatingAction(user?.email));
  };
  let dkey = data?.key;
  dkey = dkey.replaceAll('.', '-');
  const ratingInfo = useSelector((state) => selectRatingById(state)(data?.key));
  return (
    <Card className="job-post-card enhanced-post">
      <Card.Img
        variant="top"
        src={imgUrl || LogoImg}
        className="job-post-logo"
      />
      <Card.Body>
        <div className="title-section">
          <div className="title">
            <Card.Title>{data?.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {data?.company_name}
            </Card.Subtitle>
          </div>
        </div>
        <Card.Text>{renderHTML(data?.desc)}</Card.Text>
        <div className="sub-div">
          <div className="sub-info">
            <div className="sub-info-title">Requirements</div>
            <div className="sub-info-desc">{data?.requirements?.join(',')}</div>
          </div>
          <div className="sub-info">
            <div className="sub-info-title">Employment Type</div>
            <div className="sub-info-desc">
              <Badge pill bg="dark">
                {data?.job_type}
              </Badge>
            </div>
          </div>
        </div>
        {isFeatured && <ListCompanyImages />}
      </Card.Body>
      <Card.Footer>
        <div className="footer-subs-div">
          <div className="footer-sub-div">
            <span className="title">Job Discovered:</span>
            <span className="info">
              {data?.updated_at &&
                format(new Date(data?.updated_at * 1000), 'dd MMM, yyyy')}
            </span>
          </div>
          <div className="footer-sub-div action-icons">
            {!isDemo && !isAuthenticated && (
              <div className="actions d-flex">
                <div id={`faThumbsUpActiveBtn-${dkey}`} className="action-btn">
                  <span>{data.num_likes || 0}</span>
                  <FontAwesomeIcon icon={faThumbsUp} className="like-btn" />
                </div>
                <div id={`faThumbsDownBtn-${dkey}`} className="action-btn">
                  <span>{data.num_dislikes || 0}</span>
                  <FontAwesomeIcon icon={faThumbsDown} />
                </div>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`faThumbsDownBtn-${dkey}`}
                >
                  Please login to record your ratings
                </UncontrolledTooltip>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`faThumbsUpActiveBtn-${dkey}`}
                >
                  Please login to record your ratings
                </UncontrolledTooltip>
              </div>
            )}
            {!isDemo && isAuthenticated && (
              <div className="actions d-flex">
                <FontAwesomeIcon
                  icon={
                    ratingInfo?.rating === -1
                      ? faThumbsDownActive
                      : faThumbsDown
                  }
                  onClick={onDislike(data?.key)}
                />
                <FontAwesomeIcon
                  icon={
                    ratingInfo?.rating === 1 ? faThumbsUpActive : faThumbsUp
                  }
                  onClick={onLike(data?.key)}
                  className="like-btn"
                />
              </div>
            )}
          </div>
        </div>
        <Link to={`/jobs/${data?.key}`} className="apply-btn">
          Apply
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default JobPost;
