import React from 'react';
import { Link } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faSearchLocation,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import './Progress.css';

function Progress(props) {
  const { stage } = props;
  // const location = useLocation();
  // const { loginWithRedirect, isAuthenticated } = useAuth0();
  const stages = [
    {
      stage: 'create-ad',
      to: '/checkout/create-ad',
      icon: faBriefcase,
      text: 'Create',
    },
    {
      stage: 'preview',
      to: '/checkout/preview',
      icon: faSearchLocation,
      text: 'Preview',
    },
    {
      stage: 'checkout',
      to: '/checkout/final',
      icon: faShoppingCart,
      text: 'Purchase',
    },
  ];

  // if (!isAuthenticated) {
  //   localStorage.setItem('redirectTo', location.pathname);
  //   loginWithRedirect({
  //     redirectUri: `${window.location.origin}/`,
  //     appState: { returnTo: location.pathname },
  //   });
  // }

  return (
    <div className="Progress">
      <ul className="ProgressInner">
        {stages.map((stg) => (
          <li key={stg.text}>
            <Link
              className={
                stg.stage === stage ? 'Progress-Item Selected' : 'Progress-Item'
              }
              to={stg.to}
            >
              <FontAwesomeIcon icon={stg.icon} /> &nbsp; {stg.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Progress;
