import React, { useEffect, useState } from 'react';
import { getContentBlogAPI, useLazyGetJobAdsFromBlogQuery } from 'api/blog';
import { useParams, Link } from 'react-router-dom';
import ThumbnailLoading from 'common/ThumbnailLoading';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import NewsletterPopup from 'Homepage/NewsletterPopup';
import Byline from './Byline';
import Job from '../search/Job';
// import FeaturedBlog from './FeaturedBlog';
import './BlogPage.css';
import './BlogDetail/index.css';

function BlogPage() {
  const { pathname } = window.location;
  const iii = pathname.lastIndexOf('-');
  const timestamp = parseInt(pathname.substring(iii + 1, pathname.length), 10);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [abstract, setAbstract] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [content, setContent] = useState('');
  const [authorImage, setAuthorImage] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [readNextBlogs, setReadNextBlogs] = useState([]);
  const { topic, year, slug } = useParams();
  const [getJobAdsFromBlog, { data: jobs }] = useLazyGetJobAdsFromBlogQuery();
  useEffect(() => {
    getJobAdsFromBlog({
      topic,
      year,
      slug,
    });
  }, []);

  useEffect(() => {
    const getBlogInfo = async (t) => {
      try {
        const [resp] = await Promise.all([getContentBlogAPI(t)]);
        setIsLoading(false);
        setTitle(resp.title);
        setContent(resp.content);
        setAbstract(resp.abstract);
        setMainImage(resp.main_image);
        setAuthorImage(resp.authors[0].image_url);
        setAuthorName(resp.authors[0].name);
        setReadNextBlogs(resp.read_next);
      } catch (ex) {
        console.log(ex);
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    getBlogInfo(timestamp);

    return () => {
      setContent(null);
    };
  }, [timestamp]);

  if (isLoading)
    return (
      <div className="BlogPage">
        <ThumbnailLoading />
      </div>
    );

  // <FeaturedBlog {...featuredBlogData} />
  // <FeaturedBlog {...featuredBlogData} />
  return (
    <div className="BlogPage">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={abstract} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={abstract} />
        <meta name="og:image" content={mainImage} />
      </Helmet>
      <div className="container d-flex">
        <NewsletterPopup />
        <div className="blog-content">
          <img src={mainImage} alt={title} />
          <div className="blog-title">{title}</div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <hr />
          <div className="byline-container">
            <Byline
              author_img_src={authorImage}
              author_name={authorName}
              date={format(new Date(timestamp), 'MM/dd/yyyy')}
            />
          </div>
        </div>
      </div>
      {readNextBlogs?.length ? (
        <div className="container d-flex">
          <div className="read-next-blog">
            <div className="title-section">Read Next</div>
            <div className="blog-row d-flex">
              {readNextBlogs.map((blog) => (
                <>
                  <div key={blog?.title} className="blog-col d-flex">
                    <div className="blog-info">
                      <div className="title">
                        <Link to={`/blog/${blog.path}-${blog.timestamp}`}>
                          {blog?.title}
                        </Link>
                      </div>
                      <div className="description">{blog?.abstract}</div>
                      <div className="blog-owner d-flex">
                        <div className="owner-info">
                          {blog?.authors.map((author) => (
                            <div key={blog?.title}>
                              <div className="avatar">
                                <img
                                  className="avatar-image"
                                  src={author?.image_url}
                                  alt={author?.name}
                                />
                              </div>
                              <div key={author?.slug} className="name">
                                {author?.name}
                              </div>
                            </div>
                          ))}
                          {blog?.timestamp && (
                            <div className="time">
                              {dayjs(blog?.timestamp).format('MM/DD/YYYY')}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="blog-thumbnail">
                      <img
                        className="bt-img"
                        src={blog?.main_image}
                        alt={blog?.title}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      {jobs?.length ? (
        <div className="SearchResults list-jobs-section">
          <Container>
            <Row>
              <p className="title">Suggested Jobs</p>
              {jobs?.map((job, index) => (
                <Col xs="12" sm="6" key={job?.id}>
                  <Job
                    key={job?.id}
                    title={job?.doc.title}
                    desc={job?.doc.desc}
                    dest_url={job?.doc.dest_url}
                    jobInfo={job?.doc}
                    index={index}
                    layout={job?.layout}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  );
}

export default BlogPage;
