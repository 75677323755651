import React from 'react';
import renderHTML from 'react-render-html';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Badge } from 'react-bootstrap';
import { UncontrolledTooltip } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import {
  faThumbsDown as faThumbsDownActive,
  faThumbsUp as faThumbsUpActive,
} from '@fortawesome/free-solid-svg-icons';
import LogoImg from 'assets/images/logo-new-job.png';
// import APIs
import { jobClickAPI } from 'api/rating';
import { jobTrackingAPI } from 'api/job';
// import redux actions
import getListRatingAction from 'redux/rating/getListRatingAction';
// import selectors
import { selectRatingById } from 'redux/rating/selector';
import './index.css';

const FreeJobPost = ({
  isBasic = false,
  data,
  index,
  query,
  isDemo = false,
  imgUrl,
}) => {
  const { isAuthenticated, user } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const ratingInfo = useSelector((state) => selectRatingById(state)(data?.key));
  const settingInfo = useSelector((state) => state.setting);

  const onLike = (id) => async () => {
    await jobClickAPI({
      ...settingInfo,
      logged_in: isAuthenticated,
      logged_in_email: user?.email,
      search_term: query,
      rank: index,
      favorite: 1,
      job_id: id,
    });
    dispatch(getListRatingAction(user?.email));
  };

  const onDislike = (id) => async () => {
    await jobClickAPI({
      ...settingInfo,
      logged_in: isAuthenticated,
      logged_in_email: user?.email,
      search_term: query,
      rank: index,
      favorite: -1,
      job_id: id,
    });
    dispatch(getListRatingAction(user?.email));
  };

  const onGotoJobDetail = (jobId) => (e) => {
    e.preventDefault();
    jobTrackingAPI(jobId);
    history.push(`/jobs/${jobId}`);
  };
  let dkey = data?.key;
  dkey = dkey.replaceAll('.', '-');
  return (
    <Card className="job-post-card">
      <Card.Body>
        <div className="title-section">
          {isBasic && (
            <div className="logo">
              <img src={imgUrl || LogoImg} alt="" />
            </div>
          )}
          <div className="title">
            <Card.Title>
              <a
                href={`/jobs/${data?.key}`}
                onClick={onGotoJobDetail(data?.key)}
              >
                {data.title}
              </a>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {data?.company_name}
            </Card.Subtitle>
          </div>
        </div>
        <Card.Text>{renderHTML(data?.desc)}</Card.Text>
        <div className="sub-div">
          <div className="sub-info">
            <div className="sub-info-title">Requirements</div>
            <div className="sub-info-desc">{data?.requirements?.join(',')}</div>
          </div>
          <div className="sub-info">
            <div className="sub-info-title">Employment Type</div>
            <div className="sub-info-desc">
              <Badge pill bg="dark">
                {data?.job_type}
              </Badge>
            </div>
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="footer-subs-div">
          <div className="footer-sub-div">
            <span className="title">Job Discovered:</span>
            <span className="info">
              {data?.updated_at &&
                format(new Date(data?.updated_at * 1000), 'dd MMM, yyyy')}
            </span>
          </div>
          <div className="footer-sub-div action-icons">
            {!isDemo && !isAuthenticated && (
              <div className="actions d-flex">
                <div id={`faThumbsUpActiveBtn-${dkey}`} className="action-btn">
                  <span>{data.num_likes || 0}</span>
                  <FontAwesomeIcon icon={faThumbsUp} className="like-btn" />
                </div>
                <div id={`faThumbsDownBtn-${dkey}`} className="action-btn">
                  <span>{data.num_dislikes || 0}</span>
                  <FontAwesomeIcon icon={faThumbsDown} />
                </div>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`faThumbsDownBtn-${dkey}`}
                >
                  Please login to record your ratings
                </UncontrolledTooltip>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`faThumbsUpActiveBtn-${dkey}`}
                >
                  Please login to record your ratings
                </UncontrolledTooltip>
              </div>
            )}
            {!isDemo && isAuthenticated && (
              <div className="actions d-flex">
                <FontAwesomeIcon
                  icon={
                    ratingInfo?.rating === -1
                      ? faThumbsDownActive
                      : faThumbsDown
                  }
                  onClick={onDislike(data?.key)}
                />
                <FontAwesomeIcon
                  icon={
                    ratingInfo?.rating === 1 ? faThumbsUpActive : faThumbsUp
                  }
                  onClick={onLike(data?.key)}
                  className="like-btn"
                />
              </div>
            )}
          </div>
        </div>
        <Link to={`/jobs/${data?.key}`} className="apply-btn ">
          Apply
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default FreeJobPost;
