import { useGetListBlogsQuery } from 'api/blog';
import LatestBlogs from '../Homepage/LatestBlog';
import '../blog/BlogPage.css';

export const Blogs = () => {
  const { data } = useGetListBlogsQuery();
  return (
    <div className="BlogsListPage">
      <LatestBlogs blogs={data} />
    </div>
  );
};
