import React from 'react';
import { Helmet } from 'react-helmet';
import PricingItem from './PricingItem';
import './Pricing.css';

function Pricing() {
  const a = {
    title: 'Basic',
    price: '$25/mo',
    clazz: 'bg-success',
    features: ['one', 'two', 'three'],
    missing: ['four', 'five', 'six'],
  };
  const b = {
    title: 'Large',
    clazz: 'bg-warning',
    price: '$50/mo',
    features: ['one', 'two', 'three', 'four'],
    missing: ['five', 'six'],
  };
  const c = {
    title: 'Enterprise',
    clazz: 'bg-danger',
    price: '$75/mo',
    features: ['one', 'two', 'three', 'four', 'five', 'six'],
    missing: [],
  };
  return (
    <div className="Pricing">
      <Helmet>
        <title>Pricing</title>
      </Helmet>
      <div className="PricingTitle">Pricing</div>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <PricingItem item={a} />
            </div>
            <div className="col-lg-4">
              <PricingItem item={b} />
            </div>
            <div className="col-lg-4">
              <PricingItem item={c} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
