import React from 'react';
import { Label } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorField = ({ field, form, label, placeholder, labelCss }) => {
  const [active, setActive] = React.useState();
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  React.useEffect(() => {
    if (form.dirty) {
      return;
    }
    if (!field.value) {
      return;
    }
    const contentBlock = htmlToDraft(field.value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateCurrent = EditorState.createWithContent(contentState);
      setEditorState(editorStateCurrent);
    }
  }, [field.value, form.dirty]);

  const changeValue = (val) => {
    setEditorState(val);
    form.setFieldValue(
      field.name,
      draftToHtml(convertToRaw(val.getCurrentContent()))
    );
  };

  const onEditorStateChange = (val) => {
    changeValue(val);
  };
  const hasError = form.touched[field.name] && form.errors[field.name];
  return (
    <>
      {label && (
        <Label isActive={active} css={labelCss}>
          {label}
        </Label>
      )}
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(val) => onEditorStateChange(val)}
        placeholder={placeholder}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'link',
            'embedded',
            'remove',
            'history',
          ],
        }}
        name={field.name}
        id={field.name}
        onFocus={() => setActive(true)}
        onBlur={(e) => {
          setActive(false);
          field.onBlur(e);
        }}
      />
      {!!hasError && <div className="invalid-feedback">{hasError}</div>}
    </>
  );
};

export default EditorField;
