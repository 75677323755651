import axios from './index';

export const getIPLocal = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: `https://api.ipify.org?format=json`,
    });
    return result?.data?.ip;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
};
