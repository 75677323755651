import React from 'react';
import './PricingItemItem.css';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col } from 'reactstrap';

function PricingItemItem({ label, has_feature = false }) {
  let fa;
  if (has_feature) {
    fa = faCheck;
  } else {
    fa = faTimes;
  }
  return (
    <Container key={label}>
      <Row>
        <Col xs="2">
          <FontAwesomeIcon icon={fa} />
        </Col>
        <Col xs="10">
          <p className="piil">{label}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default PricingItemItem;
