import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie-files/not-found.json';

function NoResults() {
  return (
    <div className="SearchResults no-result-section justify-content-center no-blog-section">
      <Player autoplay loop src={animationData} className="lottie-animation" />
      <h1 className="title">No blog found</h1>
      <p>Please try with another blogs.</p>
    </div>
  );
}

export default NoResults;
