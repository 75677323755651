import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { debounce } from 'lodash';
import Loading from 'common/Loading';
import { useLazyGetListJobsQuery } from 'api/job';
import { toast } from 'react-toastify';
import BeforeSearch from './BeforeSearch';
import NoResults from './NoResults';
import Job from './Job';
import './SearchResults.css';

function SearchResults(props) {
  const { query } = props;
  const [getListJobs, { isLoading, data: jobs = [], isError, status }] =
    useLazyGetListJobsQuery();
  const [loading, setIsLoading] = useState(false);

  const search = useCallback(
    // eslint-disable-next-line
    debounce(async (text) => {
      setIsLoading(false);
      await getListJobs(text);
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      setIsLoading(true);
      search(query);
    }
  }, [query]);

  useEffect(() => {
    if (isError) {
      toast.error('Server Internal Error. Please try again!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  }, [isError]);

  const jobDivs = jobs.map((job, index) => (
    <Col xs="12" sm="6" key={job.id}>
      <Job
        key={job.id}
        title={job.doc.title}
        desc={job.doc.desc}
        dest_url={job.doc.dest_url}
        jobInfo={job?.doc}
        index={index}
        layout={job.layout}
        query={query}
      />
    </Col>
  ));
  if (loading || status === 'pending' || isLoading) return <Loading />;

  if (!query && jobs.length === 0) {
    return <BeforeSearch />;
  }
  if (jobDivs.length === 0) {
    return <NoResults query={query} />;
  }
  return (
    <div className="SearchResults list-jobs-section">
      <Container>
        <Row>{jobDivs}</Row>
      </Container>
    </div>
  );
}

export default SearchResults;
