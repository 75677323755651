import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie-files/loading.json';

const Loading = () => (
  <div className="search-loading">
    <Player autoplay loop src={animationData} className="lottie-animation" />
  </div>
);

export default Loading;
