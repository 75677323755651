import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import Loading from 'common/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
// import APIs
import {
  getListSubscriptionsAPI,
  cancelSubscriptionAPI,
} from 'api/subscription';
import './index.css';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const Subscription = () => {
  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [listSubscriptions, setListSubscriptions] = useState([]);

  const fetchListSubscription = async () => {
    setIsLoading(true);
    const data = await getListSubscriptionsAPI(user?.email);
    setIsLoading(false);
    setListSubscriptions(data);
  };

  useEffect(() => {
    fetchListSubscription();
  }, [user]);

  const handleClose = () => setSelectedId(undefined);
  const handleShow = (id) => () => {
    setSelectedId(id);
  };
  const onCancelSubscription = async () => {
    try {
      await cancelSubscriptionAPI({
        email: user.email,
        subscription_id: selectedId,
      });
      setSelectedId(undefined);
      fetchListSubscription();
    } catch (ex) {
      console.error(ex);
      toast.error('Server Internal Error. Please try again!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };

  return (
    <div className="container subscription-page">
      <p className="title">Your subscriptions</p>
      {isLoading && <Loading />}
      {!isLoading && (
        <Table responsive>
          <thead>
            <tr>
              <th>Billing</th>
              <th>Status</th>
              {!user ? <th>Canceled at</th> : null}
              <th>Current period start</th>
              <th>Current period end</th>
              <th>Plan amount</th>
              <th>Plan interval</th>
              <th>Plan name</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {listSubscriptions.map((subscription) => (
              <tr>
                <td>{subscription?.billing}</td>
                <td>{subscription?.status}</td>
                {!user ? (
                  <td>
                    {subscription?.canceled_at
                      ? format(
                          new Date(subscription?.canceled_at * 1000),
                          'PP',
                          {
                            locale: enGB,
                          }
                        )
                      : ''}
                  </td>
                ) : null}
                <td>
                  {subscription?.current_period_start
                    ? format(
                        new Date(subscription?.current_period_start * 1000),
                        'PP',
                        { locale: enGB }
                      )
                    : ''}
                </td>
                <td>
                  {subscription?.current_period_end
                    ? format(
                        new Date(subscription?.current_period_end * 1000),
                        'PP',
                        { locale: enGB }
                      )
                    : ''}
                </td>
                <td>
                  {subscription?.plan?.amount
                    ? formatter.format(subscription?.plan?.amount / 100)
                    : ''}
                </td>
                <td>{subscription?.plan?.interval}</td>
                <td>{subscription?.plan?.name}</td>
                <td>
                  <Button
                    variant="primary"
                    onClick={handleShow(subscription?.id)}
                  >
                    Cancel
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={!!selectedId} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this subscription?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={onCancelSubscription}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Subscription;
