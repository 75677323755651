import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie-files/not-found.json';

const NoData = () => (
  <div className="SearchResults no-result-section justify-content-center">
    <Player autoplay loop src={animationData} className="lottie-animation" />
    <h1 className="title">No preview data</h1>
  </div>
);

export default NoData;
